<template lang="pug">
	main(:class="{isHome}")#pagina
		Banner(:contents="banner", v-if="banner.title && !isHome")
		article(:class="{ hasNavigation }")#content
			Navegacao(:contents="navigation", v-if="hasNavigation")
			Conteudo(:contents="blocks", :newsTitle="isNoticia ? translation.title : null" :title="isNoticia ? 'Notícia' : translation.title", v-if="blocks", :class="{ hasNavigation, isNoticia, isIndustrias, isTrabalhe }" :newsDate="isNoticia ? translation.publishedAt : null" )
			Compartilhamento(v-if="hasNavigation || isNoticia" :class="{ isNoticia }")
</template>

<script>
import Banner from '@sections/Pagina/Banner/Banner.vue'
import Compartilhamento from '@sections/Pagina/Compartilhamento/Compartilhamento.vue'
import Conteudo from '@sections/Pagina/Conteudo/Conteudo.vue'
import Navegacao from '@sections/Pagina/Navegacao/Navegacao.vue'
import pagina from '@/mixins/pagina'

export default {
	name: "view-pagina",
	mixins: [ pagina ],
	components: {
		Banner,
		Compartilhamento,
		Conteudo,
		Navegacao,
	},
	computed: {
		banner() {
			const banner = this.translation.banner
			if (banner)
				banner.url = banner.filePath

			return {
				title: this.isNoticia ? 'Notícias' : this.translation.title,
				banner: banner ? banner : {
					url: require(`@images/paginas/banner-pagina.jpg`),
					altText: this.translation.title
				}
			}
		},
	},
}
</script>

<style lang="stylus" scoped src="./Pagina.styl"></style>
